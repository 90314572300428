<template>
  <v-card width="100%" style="padding-left: 10px; padding-right: 10px;">   
          <v-row>
            
            <v-col cols="12" xs="12" sm="12" md="12">
              <div><b>Acta de Valoración</b></div>
            </v-col>
            <v-col cols="6" xs="6" sm="6" md="6">
             <!-- aqui va fileupload -->
                <uploadFile4 
                :archivoId ="this.$store.state.seguimiento.id_ActaValoracion"
                action_a_Ejecutar ="action_seguimiento_id_ActaValoracion"
                :variableContador=  "this.$store.state.seguimiento.doctosCargados"
                action_variableContador ="action_seguimiento_doctosCargados">              
                </uploadFile4> 
            </v-col>
          </v-row>
    </v-card>
     
</template>

<script >


export default {

      name: 'cardNotificacionPersona', 

      props :{

          valorcombo : { type:String , default: ''},
          incidenteId : { type: String , default : '0'},
          archivoId : {type:String, default :'0'},
          action_plan :{type:Boolean , default :false},
          nombreDelArchivo:{ type:String , default : 'Sin asignar'},
          sihayarchivo:{type:Boolean , default :false},

      },

      components : {
           
           uploadFile4 : () => import('@/components/manipulacionArchivos/uploadFile5.vue')

      },

      data() {

          return {
               itemsOpciones: ["SI", "NO", "POR CONFIRMAR"],
          }
      },

      methods : {
         asignarValor(event){

            this.$store.dispatch("action_seguimiento_id_ActaValoracion", event);
         }
      }

    }
</script>

<style scoped>

</style>